@font-face {
  font-family: 'Comic Neue Bold';
  src: local('Comic Neue Bold'), local('ComicNeue-Bold'),
    url('../public/fonts/ComicNeue-Bold.woff2') format('woff2'),
    url('../public/fonts/ComicNeue-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Comic Neue Bold Italic';
  src: local('Comic Neue Bold Italic'), local('ComicNeue-Bold-Italic'),
    url('../public/fonts/ComicNeue-BoldItalic.woff2') format('woff2'),
    url('../public/fonts/ComicNeue-BoldItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
body {
  margin: 0;
  font-family: 'Comic Neue Bold',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: floralwhite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border-radius: 5px;
  background-color: lightskyblue;
  font-weight: bold;
  /*display: flex;*/
}

label {
  display: flex;
  background-color: antiquewhite;
  font-family: cursive
}


.App {
  text-align: left;
  background-color: floralwhite;
  min-height: 100vh;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
}